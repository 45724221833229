@import "https://fonts.googleapis.com/css?family=Roboto+Mono:100";
#person[data-v-69130d] {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  font-size: 5vmin;
  font-weight: 100;
  display: flex;
}

#person h1[data-v-69130d] {
  font-family: Roboto Mono, monospace;
}

#person p[data-v-69130d] {
  margin-bottom: 20px;
}

.gw-glitched {
  color: #757575;
}

#credit[data-v-a5d378] {
  float: right;
  margin: 1rem 2rem 1rem 1rem;
  font-size: 3vmin;
  position: relative;
}

#credit a[data-v-a5d378] {
  color: var(--color-text);
  text-decoration: none;
}

#credit a[data-v-a5d378]:hover {
  text-decoration: underline;
}

.fullPageSection[data-v-a5d378] {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
}

#first .text[data-v-a5d378] {
  color: #fafafa;
  margin-top: -5vh;
  font-size: 7.5vmin;
  font-weight: 100;
  position: absolute;
}

.centered[data-v-a5d378] {
  width: 80vmin;
  margin: auto;
  display: block;
}

#person[data-v-69130d] {
  width: 100%;
  height: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 10vw;
  font-size: 5vmin;
  font-weight: 100;
  display: flex;
}

#person h1[data-v-69130d] {
  font-family: Roboto Mono, monospace;
}

#person p[data-v-69130d] {
  margin-bottom: 20px;
}

::-webkit-scrollbar {
  width: 17px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  background-clip: content-box;
  border: 6px solid rgba(0, 0, 0, 0);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

* {
  overflow-y: overlay;
  overflow-x: hidden;
}

@keyframes fromdown {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.fromdown-enter-active {
  animation: .5s fromdown;
}

.fromdown-leave-active {
  animation: .5s reverse fromdown;
}

.bouncefade-enter-active, .bouncefade-leave-active {
  transition: all 1s;
}

.bouncefade-enter-from, .bouncefade-leave-to {
  opacity: 0;
  transform: scale(2);
}

:root {
  --vt-c-white: #fff;
  --vt-c-white-soft: #f8f8f8;
  --vt-c-white-mute: #f2f2f2;
  --vt-c-black: #181818;
  --vt-c-black-soft: #222;
  --vt-c-black-mute: #282828;
  --vt-c-indigo: #2c3e50;
  --vt-c-divider-light-1: rgba(60, 60, 60, .29);
  --vt-c-divider-light-2: rgba(60, 60, 60, .12);
  --vt-c-divider-dark-1: rgba(84, 84, 84, .65);
  --vt-c-divider-dark-2: rgba(84, 84, 84, .48);
  --vt-c-text-light-1: var(--vt-c-indigo);
  --vt-c-text-light-2: rgba(60, 60, 60, .66);
  --vt-c-text-dark-1: var(--vt-c-white);
  --vt-c-text-dark-2: rgba(235, 235, 235, .64);
  --color-background: var(--vt-c-white);
  --color-background-soft: var(--vt-c-white-soft);
  --color-background-mute: var(--vt-c-white-mute);
  --color-border: var(--vt-c-divider-light-2);
  --color-border-hover: var(--vt-c-divider-light-1);
  --color-heading: var(--vt-c-text-light-1);
  --color-text: var(--vt-c-text-light-1);
  --section-gap: 160px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-background: var(--vt-c-black);
    --color-background-soft: var(--vt-c-black-soft);
    --color-background-mute: var(--vt-c-black-mute);
    --color-border: var(--vt-c-divider-dark-2);
    --color-border-hover: var(--vt-c-divider-dark-1);
    --color-heading: var(--vt-c-text-dark-1);
    --color-text: var(--vt-c-text-dark-2);
  }
}

*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  font-weight: normal;
  position: relative;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  color: var(--color-text);
  background: var(--color-background);
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Inter, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  transition: color .5s, background-color .5s;
}

.gw-glitched {
  color: #757575;
}

#credit[data-v-a5d378] {
  float: right;
  margin: 1rem 2rem 1rem 1rem;
  font-size: 3vmin;
  position: relative;
}

#credit a[data-v-a5d378] {
  color: var(--color-text);
  text-decoration: none;
}

#credit a[data-v-a5d378]:hover {
  text-decoration: underline;
}

.fullPageSection[data-v-a5d378] {
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 0;
  left: 0;
}

#first .text[data-v-a5d378] {
  color: #fafafa;
  margin-top: -5vh;
  font-size: 7.5vmin;
  font-weight: 100;
  position: absolute;
}

.centered[data-v-a5d378] {
  width: 80vmin;
  margin: auto;
  display: block;
}

/*# sourceMappingURL=index.393586a4.css.map */
